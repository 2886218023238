import React, { useState } from 'react';
import SkeletonForm from "./SkeletonForm";

function GruppeThemaForm({item, callbackSaveCategory, callbackClose}){
    const [title, setTitle] = useState(item.name);

    const callbackSave = () => {
        callbackSaveCategory(item, {name: title});
    }

    return(
        <SkeletonForm callbackOk={callbackSave} callbackClose={callbackClose}>
            <h2>Gruppe bearbeiten</h2>
            <label>Titel</label><input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
        </SkeletonForm>
    );
}

export default GruppeThemaForm;
