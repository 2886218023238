import React, { useState } from 'react';
import SkeletonForm from "./SkeletonForm";

function KategorieForm({item, callbackSaveCategory, callbackClose}){
    const [title, setTitle] = useState(item.name);
    const [color, setColor] = useState(item.color);
    const [column, setColumn] = useState(item.column);

    const callbackSave = () => {
        callbackSaveCategory(item, {name: title, color, column});
    }

    return(
        <SkeletonForm callbackOk={callbackSave} callbackClose={callbackClose}>
            <h2>Kategorie bearbeiten</h2>
            <label>Titel</label><input type="text" value={title} onChange={(e) => setTitle(e.target.value)}/>
            <label>Farbe</label><input type="color" value={color} onChange={(e) => setColor(e.target.value)}/>
            <label>Spalte</label><select value={column} onChange={(e) => setColumn(e.target.value)} >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
            </select>
        </SkeletonForm>
    );
}

export default KategorieForm;
