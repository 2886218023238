import './App.css';
import Bildungsplaner from './components/Bildungsplaner';
import Auswahl from './components/Auswahl';
import Notification from './components/Notification';
import {useState} from 'react'
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import DeleteForm from "./components/forms/DeleteForm";
import ItemForm from "./components/forms/ItemForm";
import {
    deleteCategory,
    deleteCourse,
    fetchEventoEvents,
    fetchEventoToken,
    moveItemToNewParent,
    reorderItem,
    updateCategory,
    updateCourse
} from "./api_connectors/crmApiConnector";

document.addEventListener('DOMContentLoaded', function() {
    fetchEventoToken()
        .then(token => {
            console.log('Token received:', token);
            return fetchEventoEvents(token);
        })
        .then(events => console.log('Events:', events))
        .catch(error => console.error('Error:', error));
});

function App() {
    const [editModalItem, setEditModalItem] = useState(null);
    const [deleteModalItem, setDeleteModalItem] = useState(null);
    const [shouldFetchData, setShouldFetchData] = useState(0);
    const [notificationMessage, setNotificationMessage] = useState(null);

    const updateData = () => {
        setShouldFetchData(shouldFetchData + 1);
    }

    const saveCategory = async (item, attributes) => {
        const mergedData = {
            ...item,
            ...attributes
        };
        try {
            const serverResponse = await updateCategory(item.id, mergedData);
            console.log('Category updated successfully:', serverResponse);
            setEditModalItem(null);
            updateData();
            return serverResponse;
        } catch (error) {
            console.error('Failed to update category:', error);
            throw error;
        }
    }

    const saveCourse = async (item, attributes) => {
        const mergedData = {
            ...item,
            ...attributes
        };
        try {
            const serverResponse = await updateCourse(item.id, mergedData);
            console.log('Category updated successfully:', serverResponse);
            setEditModalItem(null);
            updateData();
            return serverResponse;
        } catch (error) {
            console.error('Failed to update category:', error);
            throw error;
        }
    }

    const deleteItem = (item) => {
        if (item.type === "course") {
            return deleteCourse(item.id).then(() => {
                setDeleteModalItem(null);
                item.deleted = true;
                updateData();
            });
        } else if (item.type === "category") {
            return deleteCategory(item.id).then(() => {
                setDeleteModalItem(null);
                item.deleted = true;
                updateData();
            });
        } else {
            throw "Type invalid: " + item.type;
        }
    }

    const dropItemToSibling = (dragItem, dropItem) => {
        const dragItemType = (dragItem.type === "kurs" || dragItem.type === "course") ? "course" : "category";
        const dropItemType = (dropItem.type === "kurs" || dropItem.type === "course") ? "course" : "category";
        if (dragItem.id !== dropItem.id) {
            return reorderItem(dragItem.id, dragItemType, dropItem.id, dropItemType).then(() => {
                dragItem.reordered = true;
                updateData();
            });
        }
    }

    const dropItemToContainer = (dragItem, dropItem) => {
        const dragItemType = (dragItem.type === "kurs" || dragItem.type === "course") ? "course" : "category";
        return moveItemToNewParent(dragItem.id, dragItemType, dropItem.id).then(() => {
            dragItem.reordered = true;
            updateData();
        });
    }

    return (
        <DndProvider backend={HTML5Backend}>
            {
                notificationMessage &&
                <Notification
                    message={notificationMessage}
                    setNotificationMessage={setNotificationMessage}
                />
            }
            <div className='planerBackend'>
                <div className='BPbox'>
                    <Bildungsplaner
                        setShouldFetchData={setShouldFetchData}
                        shouldFetchData={shouldFetchData}
                        updateData={updateData}
                        setEditModalItem={setEditModalItem}
                        setDeleteModalItem={setDeleteModalItem}
                        dropItemToSibling={dropItemToSibling}
                        dropItemToContainer={dropItemToContainer}
                        setNotificationMessage={setNotificationMessage}
                    />
                </div>
                <Auswahl
                    setNotificationMessage={setNotificationMessage}
                />
            </div>
            {
                deleteModalItem &&
                <DeleteForm
                    item={deleteModalItem}
                    callbackOk={() => {deleteItem(deleteModalItem)}}
                    callbackClose={() => {setDeleteModalItem(null)}}/>
            }
            {
                editModalItem &&
                <ItemForm
                    item={editModalItem}
                    callbackSaveCategory={saveCategory}
                    callbackSaveCourse={saveCourse}
                    callbackClose={() => {setEditModalItem(null)}}/>
            }
        </DndProvider>
    );
}

export default App;
