import {useDrop} from "react-dnd";
import Kategorie from "./Kategorie";
import React from "react";
import {createCategory, updateCategory} from "../../api_connectors/crmApiConnector";
import {randomColor} from "../../framework/util";

const Spalte = ({spalte, bildungsplanerData, setEditModalItem, setDeleteModalItem, dropItemToSibling, dropItemToContainer, updateData, setNotificationMessage}) => {
    const [{ isOver: isOver1, canDrop: canDrop1 }, drop1] = useDrop({
        accept: ['kategorie', 'neue_kategorie'],
        drop: (item) => handleDrop(1, item),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }),
    });

    const [{ isOver: isOver2, canDrop: canDrop2 }, drop2] = useDrop({
        accept: ['kategorie', 'neue_kategorie'],
        drop: (item) => handleDrop(2, item),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }),
    });

    const [{ isOver: isOver3, canDrop: canDrop3 }, drop3] = useDrop({
        accept: ['kategorie', 'neue_kategorie'],
        drop: (item) => handleDrop(3, item),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }),
    });

    const [{ isOver: isOver4, canDrop: canDrop4 }, drop4] = useDrop({
        accept: ['kategorie', 'neue_kategorie'],
        drop: (item) => handleDrop(4, item),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }),
    });

    const handleDrop = async (columnNumber, item) => {
        if (item.type === 'kategorie') {
            const categoryItem = item.item;
            categoryItem.column = columnNumber;
            return await updateCategory(categoryItem.id, categoryItem).then(() => {
                updateData();
            });
        }
        if (item.type === 'neue_kategorie') {
            return await createCategory({
                parent_category_id: null,
                name: "Neue Kategorie",
                active_flag: true,
                color: randomColor(),
                column: columnNumber,
            }).then(() => {
                updateData();
            });
        }
    };

    const dropRefs = [drop1, drop2, drop3, drop4];
    const isOverStates = [isOver1, isOver2, isOver3, isOver4];
    const canDropStates = [canDrop1, canDrop2, canDrop3, canDrop4];

    return(
        <div className={"bpColumn bpc" + spalte} key={spalte}>
            <div className="planer-line planer-spalte-title">
                <div className="spalte-tabs">
                    {[1, 2, 3, 4].map((num, index) => (
                        <div
                            key={num}
                            ref={dropRefs[index]}
                            className={`spalte-tab ${num === spalte ? 'active' : ''} ${canDropStates[index] ? 'can-drop' : ''} ${isOverStates[index] ? 'is-over' : ''}`}
                        >
                            Spalte {num}
                        </div>
                    ))}
                </div>
            </div>
            {
                bildungsplanerData.filter((bildungsplanerEintrag) => bildungsplanerEintrag.column === spalte).map(
                    (bildungsplanerEintrag) => (
                        <Kategorie
                            key={bildungsplanerEintrag.id}
                            categoryItem={bildungsplanerEintrag}
                            setEditModalItem={setEditModalItem}
                            setDeleteModalItem={setDeleteModalItem}
                            dropItemToSibling={dropItemToSibling}
                            dropItemToContainer={dropItemToContainer}
                            updateData={updateData}
                            setNotificationMessage={setNotificationMessage}
                        />
                    )
                )
            }
        </div>
    );
};

export default Spalte;
