import Thema from "./Thema";
import ToggleButton from '../buttons/ToggleButton';
import EditButton from '../buttons/EditButton';
import DelButton from '../buttons/DelButton';
import {createCategory, updateCategory} from "../../api_connectors/crmApiConnector";
import {useDrag, useDrop} from "react-dnd";

const Gruppe = ({categoryItem, setEditModalItem, setDeleteModalItem, dropItemToSibling, dropItemToContainer, updateData, setNotificationMessage})=>{
    const [{isDragging}, drag] = useDrag(
        () => ({
            type: 'gruppe',
            item: {
                type: 'gruppe',
                id: categoryItem.id,
                item: categoryItem,
            },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            })
        }),
        []
    );
    const [{isOver, canDrop}, drop] = useDrop({
        accept: ['gruppe', 'neue_gruppe', 'thema', 'neues_thema'],
        drop: async (item, monitor) => {
            if (item.type === 'gruppe') {
                await dropItemToSibling(item, categoryItem);
            }
            if (item.type === 'thema') {
                await dropItemToContainer(item, categoryItem);
            }
            if (item.type === 'neue_gruppe') {
                return await createCategory({
                    parent_category_id: categoryItem.parent_category_id,
                    name: "Neue Gruppe",
                    active_flag: true,
                    color: null,
                    column: categoryItem.column,
                }).then(() => {
                    updateData();
                });
            }
            if (item.type === 'neues_thema') {
                return await createCategory({
                    parent_category_id: categoryItem.id,
                    name: "Neues Thema",
                    active_flag: true,
                    color: null,
                    column: categoryItem.column,
                }).then(() => {
                    updateData();
                });
            }
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }),
    });

    if (categoryItem.deleted) {
        return <></>;
    }

    const title = categoryItem.name;
    const themes = categoryItem.items;

    const callbackApiToggle = async () => {
        categoryItem.active_flag = !categoryItem.active_flag;
        return await updateCategory(categoryItem.id, categoryItem);
    };

    const onClick = () => {
        if (!isDragging) {
            setNotificationMessage("Gruppe mit drag&drop verschieben.");
        }
    };

    return(
        <div className="bpGroup">
            <div
                ref={(e) => { drag(e); drop(e); }}
                className={"planer-line planer-group-title" + (canDrop ? " can-drop" : "") + (isOver ? " is-over" : "")}
                style={{ opacity: isDragging ? 0.5 : 1 }}
                onClick={onClick}
            >
                <p>
                    {title || "(Kein Titel)"}
                    <span className="planer-line-addition">
                        <ToggleButton isActive={categoryItem.active_flag} callbackApiToggle={callbackApiToggle} />
                        <EditButton callbackEdit={() => {setEditModalItem(categoryItem)}} canEdit={true} />
                        <DelButton callbackDelete={() => {setDeleteModalItem(categoryItem)}} />
                    </span>
                </p>
            </div>
            {
                (themes || []).map((theme) => (
                    <Thema
                        key={theme.id}
                        categoryItem={theme}
                        setEditModalItem={setEditModalItem}
                        setDeleteModalItem={setDeleteModalItem}
                        dropItemToSibling={dropItemToSibling}
                        dropItemToContainer={dropItemToContainer}
                        updateData={updateData}
                        setNotificationMessage={setNotificationMessage}
                    />
                ))
            }
        </div>
    );
};

export default Gruppe;
