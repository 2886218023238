function DelButton({callbackDelete}){
    return(
        <span className="imgButton" onClick={callbackDelete}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="1rem" y="1rem" viewBox="0 0 11 11" className="papierkorb">
                <path d="M 2.3 9.55 c 0 0.55 0.4 0.95 0.95 0.95 h 4.5 c 0.55 0 0.95 -0.4 0.95 -0.95 v -6.25 h -6.4 v 6.25 z M 7 4.45 h 0.5 v 4.9 h -0.5 v -4.9 z m -1.75 0 h 0.5 v 4.9 h -0.5 v -4.9 z m -1.75 0 h 0.5 v 4.9 h -0.5 c 0 0 0 -4.9 0 -4.9 z M 9.05 1.6 h -2.2 l -0.1 -0.6 c -0.1 -0.3 -0.35 -0.5 -0.65 -0.5 h -1.2 c -0.3 0 -0.55 0.2 -0.65 0.5 l -0.1 0.6 h -2.2 c -0.1 0 -0.2 0.1 -0.2 0.2 v 0.8 c 0 0.1 0.1 0.2 0.2 0.2 h 7.1 c 0.1 0 0.2 -0.1 0.2 -0.2 v -0.8 c 0 -0.1 -0.1 -0.2 -0.2 -0.2 z M 4.75 1.15 c 0 -0.1 0.05 -0.15 0.15 -0.15 h 1.2 c 0.05 0 0.15 0.05 0.15 0.15 l 0.1 0.45 h -1.7 l 0.1 -0.45 z"/>
            </svg>
        </span>
    );
}

export default DelButton;
