import {useEffect, useState} from "react";

function ToggleButton({isActive, callbackApiToggle}){
    const [on, setOn] = useState(true);
    useEffect(() => {
        setOn(isActive);
    }, [isActive]);
    function switchToggle(){
        setOn(!on);
        callbackApiToggle();
    }
    return(
        <span className="imgButton toggle" onClick={switchToggle}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="2rem" y="1rem" viewBox="0 0 22 11" className={"toggle"+(on?"":" off")}>
                <path className="background" d="M 5.7 10.2 h 10.6 c 2.6 0 4.7 -2.1 4.7 -4.7 s -2.1 -4.7 -4.7 -4.7 h -10.6 c -2.6 0 -4.7 2.1 -4.7 4.7 s 2.1 4.7 4.7 4.7 Z"/>
                <path className="nob" d="M 16.3 2.5 c 1.7 0 3 1.3 3 3 s -1.3 3 -3 3 s -3 -1.3 -3 -3 s 1.3 -3 3 -3 Z"/>
            </svg>
        </span>
    );
}

export default ToggleButton;
