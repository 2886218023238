import { useState } from "react";

function Notification({message, setNotificationMessage}) {
    const [isDisplayed, setIsDisplayed] = useState(false);

    if(isDisplayed == false){
        setIsDisplayed(true);
        setTimeout(()=>{
            setNotificationMessage(null);
            setIsDisplayed(false);
        }, 2000);
    }

    return (
        <div className="Notification">
            <p>{message}</p>
        </div>
    );
}

export default Notification;