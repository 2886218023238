import React, { useEffect, useState } from 'react';
import {fetchBildungsplanerData} from "../api_connectors/crmApiConnector";
import Spalte from "./struktur/Spalte";

const Bildungsplaner = ({setEditModalItem, setDeleteModalItem, updateData, shouldFetchData, dropItemToSibling, dropItemToContainer, setNotificationMessage}) => {
    const [bildungsplanerData, setBildungsplanerData] = useState([]);

    useEffect(() => {
        const initBildungsplanerData = async () => {
            try {
                const data = await fetchBildungsplanerData();
                setBildungsplanerData(data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        initBildungsplanerData();
    }, [shouldFetchData]);

    return (
        <div className="bpMain">
            {
                [1, 2, 3, 4].map((spalte) => (
                    <Spalte
                        key={spalte}
                        spalte={spalte}
                        bildungsplanerData={bildungsplanerData}
                        setEditModalItem={setEditModalItem}
                        setDeleteModalItem={setDeleteModalItem}
                        dropItemToSibling={dropItemToSibling}
                        dropItemToContainer={dropItemToContainer}
                        updateData={updateData}
                        setNotificationMessage={setNotificationMessage}
                    />
                ))
            }
        </div>
    );
};

export default Bildungsplaner;
