function SkeletonForm({callbackOk, callbackClose, children}){
    return(
        <div className="modalBG">
            <div className="modalBox">
                {children}
                <div className="buttons">
                    <button type="button" onClick={callbackClose}>Abbrechen</button>
                    <button type="submit" onClick={callbackOk}>OK</button>
                </div>
            </div>
        </div>
    );
}

export default SkeletonForm;
