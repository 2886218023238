import React, { useEffect } from "react";
import { useDrag } from "react-dnd";

function EventoKursAuswahl({ eventoKurs, triggerEventoKursFilter, setNotificationMessage }) {
    const [{ isDragging }, dragEventoKurs] = useDrag(
        () => ({
            type: 'evento_kurs',
            item: {
                type: 'evento_kurs',
                kurs_id: eventoKurs.Id,
                kurs_name: eventoKurs.Designation,
            },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            })
        }),
        []
    );

    const onClick = () => {
        if (!isDragging) {
            setNotificationMessage("Eventokurs mit drag&drop in die Tabelle ziehen um ihn dort einzufügen.");
        }
    };

    return (
        <>
            <div key={eventoKurs.id} className="eventoKurs auswahllisteLine" ref={dragEventoKurs} onClick={onClick}>
                <p>
                    {eventoKurs.Designation}
                </p>
                <p className="evento-tags">
                    <span className="tag-AreaOfEducation">
                        <a href="#" onClick={() => triggerEventoKursFilter("AreaOfEducationId", eventoKurs.AreaOfEducationId, eventoKurs.AreaOfEducation)}>
                            {eventoKurs.AreaOfEducation}
                        </a>
                    </span>
                    <span className="tag-EventCategory">
                        <a href="#" onClick={() => triggerEventoKursFilter("EventCategoryId", eventoKurs.EventCategoryId, eventoKurs.EventCategory)}>
                            {eventoKurs.EventCategory}
                        </a>
                    </span>
                    <span className="tag-EventLevel">
                        <a href="#" onClick={() => triggerEventoKursFilter("EventLevelId", eventoKurs.EventLevelId, eventoKurs.EventLevel)}>
                            {eventoKurs.EventLevel}
                        </a>
                    </span>
                    <span className="tag-EventType">
                        <a href="#" onClick={() => triggerEventoKursFilter("EventTypeId", eventoKurs.EventTypeId, eventoKurs.EventType)}>
                            {eventoKurs.EventType}
                        </a>
                    </span>
                </p>
            </div>
        </>
    );
}

export default EventoKursAuswahl;
