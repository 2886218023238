import KategorieForm from './KategorieForm';
import GruppeThemaForm from './GruppeThemaForm';
import KursForm from './KursForm';

function ItemForm({item, callbackSaveCategory, callbackSaveCourse, callbackClose}) {
    if (item === undefined || item === null) {
        return <></>;
    }

    let inferredType = item.type;
    if (inferredType === "category") {
        if (item.parent_category_id === null) {
            inferredType = "kategorie"
        } else {
            inferredType = "gruppe_oder_thema";
        }
    }

    if (inferredType === "kategorie") {
        return <KategorieForm item={item} callbackSaveCategory={callbackSaveCategory} callbackClose={callbackClose} />;
    } else if (inferredType === "gruppe_oder_thema") {
        return <GruppeThemaForm item={item} callbackSaveCategory={callbackSaveCategory} callbackClose={callbackClose} />;
    } else if (inferredType === "course") {
        return <KursForm item={item} callbackSaveCourse={callbackSaveCourse} callbackClose={callbackClose} />;
    }

    console.error("selected item not found")
    console.log(inferredType);
    console.log(item);

    return <></>;
}

export default ItemForm;
