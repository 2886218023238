import {useDrag} from "react-dnd";

function KategorieAuswahl({setNotificationMessage}) {
    const [{isDragging}, dragKategorie] = useDrag(
        () => ({
            type: 'neue_kategorie',
            item: {
                type: 'neue_kategorie',
            },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            })
        }),
        []
    );

    const onClick = () => {
        if (!isDragging) {
            setNotificationMessage("'Neue Kategorie' mit drag&drop in die Tabelle ziehen um eine neue Kategorie zu erstellen.");
        }
    };

    return(
        <>
            <div className="neuesElement neueKategorie auswahllisteLine" ref={dragKategorie} onClick={onClick}><p>Neue Kategorie</p></div>
        </>
    );
}

export default KategorieAuswahl;
