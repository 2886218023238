import React from "react";
import ToggleButton from "../buttons/ToggleButton";
import EditButton from "../buttons/EditButton";
import DelButton from "../buttons/DelButton";
import {createCourse, updateCourse} from "../../api_connectors/crmApiConnector";
import {useDrag, useDrop} from "react-dnd";

function Kurs({kursItem, setEditModalItem, setDeleteModalItem, dropItemToSibling, dropItemToContainer, updateData, setNotificationMessage}) {
    const [{isDragging}, drag] = useDrag(
        () => ({
            type: 'kurs',
            item: {
                type: 'kurs',
                id: kursItem.id,
                item: kursItem,
            },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            })
        }),
        []
    );
    const [{isOver, canDrop}, drop] = useDrop({
        accept: ['kurs', 'neuer_kurs', 'evento_kurs'],
        drop: async (item, monitor) => {
            if (item.type === 'kurs') {
                await dropItemToSibling(item, kursItem);
            }
            if (item.type === 'neuer_kurs') {
                return await createCourse({
                    parent_category_id: kursItem.parent_category_id,
                    name: "Neuer Kurs",
                    active_flag: true,
                    html_text: "Neuer Kurs",
                    evento_id: null,
                }).then(() => {
                    updateData();
                });
            }
            if (item.type === 'evento_kurs') {
                return await createCourse({
                    parent_category_id: kursItem.parent_category_id,
                    name: item.kurs_name,
                    active_flag: true,
                    html_text: null,
                    evento_id: item.kurs_id,
                }).then(() => {
                    updateData();
                });
            }
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }),
    });

    if (kursItem.deleted) {
        return <></>;
    }

    const kursCallbackApiToggle = (courseItem) => {
        return async () => {
            courseItem.active_flag = !courseItem.active_flag;
            return await updateCourse(courseItem.id, courseItem);
        }
    };

    const onClick = () => {
        if (!isDragging) {
            setNotificationMessage("Kurs mit drag&drop verschieben.");
        }
    };

    return (
        <>
            <div
                ref={(e) => { drag(e); drop(e); }}
                className={"planer-line planer-entry planer-entry-subpoint" + (canDrop ? " can-drop" : "") + (isOver ? " is-over" : "")}
                style={{ opacity: isDragging ? 0.5 : 1 }}
                onClick={onClick}
            >
                <p>
                    {kursItem.evento_id && <img src="/bkd_logo.jpg" className={"planer-entry-evento-logo"}></img>}
                    {kursItem.name || "(Kein Titel)"}
                    <span className="planer-line-addition">
                        <ToggleButton isActive={kursItem.active_flag} callbackApiToggle={kursCallbackApiToggle(kursItem)}/>
                        <EditButton
                            callbackEdit={() => {
                                setEditModalItem(kursItem)
                            }}
                            canEdit={!kursItem.evento_id}
                        />
                        <DelButton callbackDelete={() => {
                            setDeleteModalItem(kursItem)
                        }}/>
                        </span>
                </p>
            </div>
        </>
    );
}

export default Kurs;
