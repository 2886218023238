import crmApiConfig from '../config/crmApiConfig';

const fetchWithToken = async (url, args = {}) => {
    const token = crmApiConfig.token;
    const headers = new Headers(args.headers || {});
    headers.set('Authorization', `Bearer ${token}`);
   return fetch(url, {
        ...args,
        headers: headers
    });
}

export async function fetchBildungsplanerData() {
    try {
        const response = await fetchWithToken(`${crmApiConfig.apiRoot}/hierarchical_categories`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
}

export async function fetchEventoToken() {
    try {
        const response = await fetchWithToken(`${crmApiConfig.apiRoot}/evento_token`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.access_token;
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
}

export async function fetchEventoEvents(token) {
    try {
        const response = await fetchWithToken(`${crmApiConfig.apiRoot}/evento_events`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                evento_token: token
            })
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
}
export async function createCategory(categoryData) {
    try {
        const response = await fetchWithToken(`${crmApiConfig.apiRoot}/categories`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                parent_category_id: categoryData.parent_category_id,
                name: categoryData.name,
                active_flag: categoryData.active_flag,
                color: categoryData.color,
                column: categoryData.column,
            }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Create category error:', error);
        throw error;
    }
}

export async function updateCategory(categoryId, categoryData) {
    try {
        const response = await fetchWithToken(`${crmApiConfig.apiRoot}/categories/${categoryId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                parent_category_id: categoryData.parent_category_id,
                name: categoryData.name,
                active_flag: categoryData.active_flag,
                color: categoryData.color,
                column: categoryData.column,
            }),
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('Update category error:', error);
        throw error;
    }
}

export async function deleteCategory(categoryId) {
    try {
        const response = await fetchWithToken(`${crmApiConfig.apiRoot}/categories/${categoryId}`, {
            method: 'DELETE',
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return true;
    } catch (error) {
        console.error('Delete category error:', error);
        throw error;
    }
}

export async function createCourse(courseData) {
    try {
        const response = await fetchWithToken(`${crmApiConfig.apiRoot}/courses`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                parent_category_id: courseData.parent_category_id,
                name: courseData.name,
                active_flag: courseData.active_flag,
                html_text: courseData.html_text,
                evento_id: courseData.evento_id,
            }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Create course error:', error);
        throw error;
    }
}

export async function updateCourse(courseId, courseData) {
    try {
        const response = await fetchWithToken(`${crmApiConfig.apiRoot}/courses/${courseId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                parent_category_id: courseData.parent_category_id,
                name: courseData.name,
                active_flag: courseData.active_flag,
                html_text: courseData.html_text,
                evento_id: courseData.evento_id,
            }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Update course error:', error);
        throw error;
    }
}

export async function deleteCourse(courseId) {
    try {
        const response = await fetchWithToken(`${crmApiConfig.apiRoot}/courses/${courseId}`, {
            method: 'DELETE',
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return true;
    } catch (error) {
        console.error('Delete course error:', error);
        throw error;
    }
}

export async function reorderItem(dragItemId, dragItemType, dropItemId, dropItemType) {
    try {
        const response = await fetchWithToken(`${crmApiConfig.apiRoot}/reorder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                dropped_item_type: dragItemType,
                dropped_item_id: dragItemId,
                dropped_on_item_type: dropItemType,
                dropped_on_item_id: dropItemId,
            }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return true;
    } catch (error) {
        console.error('Reorder item error:', error);
        throw error;
    }
}

export async function moveItemToNewParent(dragItemId, dragItemType, dropItemId) {
    try {
        const response = await fetchWithToken(`${crmApiConfig.apiRoot}/move_to_parent`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                element_id: dragItemId,
                element_type: dragItemType,
                new_parent_id: dropItemId,
            }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return true;
    } catch (error) {
        console.error('Reorder item error:', error);
        throw error;
    }
}
