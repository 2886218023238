function EditButton({callbackEdit, canEdit}){
    if (canEdit) {
        return(
            <span className="imgButton" onClick={callbackEdit}>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="1rem" y="1rem" viewBox="0 0 11 11" className="editieren">
                    <path d="M 6.625 10.1 c 0 0.2 -0.2 0.4 -0.4 0.4 h -4.7 c -0.2 0 -0.4 -0.2 -0.4 -0.4 s 0.2 -0.4 0.4 -0.4 h 4.7 c 0.25 0 0.4 0.2 0.4 0.4 z M 9.975 2.15 l -1.4 -1.55 c -0.15 -0.15 -0.4 -0.15 -0.55 0 c 0 0 -5.6 5.15 -5.6 5.2 c -0.05 0.05 -0.05 0.05 -0.1 0.1 l -1.15 2.45 c -0.1 0.2 0 0.45 0.2 0.55 c 0.05 0 0.1 0.05 0.15 0.05 c 0.05 0 0.1 0 0.15 0 l 2.5 -0.95 c 0.05 0 0.1 -0.05 0.15 -0.1 l 5.6 -5.25 c 0.15 -0.1 0.15 -0.35 0.05 -0.5 z"/>
                </svg>
            </span>
        );
    } else {
        return(
            <span className="imgButton">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="1rem" y="1rem" viewBox="0 0 11 11" className="editieren disabled">
                    <path d="M 6.625 10.1 c 0 0.2 -0.2 0.4 -0.4 0.4 h -4.7 c -0.2 0 -0.4 -0.2 -0.4 -0.4 s 0.2 -0.4 0.4 -0.4 h 4.7 c 0.25 0 0.4 0.2 0.4 0.4 z M 9.975 2.15 l -1.4 -1.55 c -0.15 -0.15 -0.4 -0.15 -0.55 0 c 0 0 -5.6 5.15 -5.6 5.2 c -0.05 0.05 -0.05 0.05 -0.1 0.1 l -1.15 2.45 c -0.1 0.2 0 0.45 0.2 0.55 c 0.05 0 0.1 0.05 0.15 0.05 c 0.05 0 0.1 0 0.15 0 l 2.5 -0.95 c 0.05 0 0.1 -0.05 0.15 -0.1 l 5.6 -5.25 c 0.15 -0.1 0.15 -0.35 0.05 -0.5 z"/>
                </svg>
            </span>
        );
    }
}

export default EditButton;
