import React, { useState } from 'react';
import SkeletonForm from "./SkeletonForm";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function KursForm({item, callbackSaveCourse, callbackClose}){
    const [title, setTitle] = useState(item.name);
    const [editorState, setEditorState] = useState(() => {
        const blocksFromHtml = htmlToDraft(item.html_text);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        return EditorState.createWithContent(contentState);
    });

    const onEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState);
    };

    const callbackSave = () => {
        const htmlText = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        callbackSaveCourse(item, {name: title, html_text: htmlText});
    };

    return(
        <SkeletonForm callbackOk={callbackSave} callbackClose={callbackClose}>
            <h2>Kurs bearbeiten</h2>
            <label>Titel</label><input type="text" value={title} onChange={(e) => setTitle(e.target.value)}/>
            <label>Inhalt</label>
            <Editor
                editorState={editorState}
                wrapperClassName="htmleditor-wrapper"
                editorClassName="htmleditor"
                onEditorStateChange={onEditorStateChange}
            />
        </SkeletonForm>
    );
}

export default KursForm;
