import {useDrag} from "react-dnd";

function ThemaAuswahl({setNotificationMessage}) {
    const [{isDragging}, dragThema] = useDrag(
        () => ({
            type: 'neues_thema',
            item: {
                type: 'neues_thema',
            },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            })
        }),
        []
    );

    const onClick = () => {
        if (!isDragging) {
            setNotificationMessage("'Neues Thema' mit drag&drop in die Tabelle ziehen um ein neues Thema zu erstellen.");
        }
    };

    return(
        <>
            <div className="neuesElement neuesThema auswahllisteLine" ref={dragThema} onClick={onClick}><p>Neues Thema</p></div>
        </>
    );
}

export default ThemaAuswahl;
