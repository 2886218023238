import React, { useEffect, useState } from 'react';
import KategorieAuswahl from "./auswahl/KategorieAuswahl";
import GruppeAuswahl from "./auswahl/GruppeAuswahl";
import ThemaAuswahl from "./auswahl/ThemaAuswahl";
import KursAuswahl from "./auswahl/KursAuswahl";
import EventoKursAuswahl from "./auswahl/EventoKursAuswahl";
import {fetchEventoEvents, fetchEventoToken} from "../api_connectors/crmApiConnector";

function Auswahl({setNotificationMessage}) {
    const [eventoKurse, setEventoKurse] = useState([]);

    useEffect(() => {
        const fetchEventoData = async () => {
            try {
                const token = await fetchEventoToken();
                const events = await fetchEventoEvents(token);
                setEventoKurse(events);
            } catch (error) {
                console.error('Error fetching Evento data:', error);
            }
        };

        fetchEventoData();
    }, []);

    const [eventoKursFilter, setEventoKursFilter] = React.useState({
        AreaOfEducationId: null,
        EventCategoryId: null,
        EventLevelId: null,
        EventTypeId: null,
    });
    const [eventoKursFilterNames, setEventoKursFilterNames] = React.useState({
        AreaOfEducationId: null,
        EventCategoryId: null,
        EventLevelId: null,
        EventTypeId: null,
    });

    const triggerEventoKursFilter = (key, filterId, filterName) => {
        setEventoKursFilter(prevFilter => {
            const newFilter = {
                ...prevFilter,
                [key]: prevFilter[key] === filterId ? null : filterId
            };
            console.log("Updating filter:", newFilter);
            return newFilter;
        });
        setEventoKursFilterNames(prevFilterNames => {
            const newFilterNames = {
                ...prevFilterNames,
                [key]: filterName
            };
            console.log("Updating filter names:", newFilterNames);
            return newFilterNames;
        });
    }

    useEffect(() => {
        console.log("Current eventoKursFilter:", eventoKursFilter);
    }, [eventoKursFilter]);

    useEffect(() => {
        console.log("Current eventoKursFilterNames:", eventoKursFilterNames);
    }, [eventoKursFilterNames]);

    const kursIstVorbei = (eventoKurs) => {
        const now = new Date();
        if (!eventoKurs.SubscriptionDateTo && !eventoKurs.DateFrom && !eventoKurs.DateTo) {
            return false; // keine Daten gesetzt
        }
        if (eventoKurs.SubscriptionDateTo && new Date(eventoKurs.SubscriptionDateTo) > now) {
            return false; // kann noch anmelden
        }
        if (eventoKurs.DateTo && new Date(eventoKurs.DateTo) > now) {
            return false; // noch nicht fertig
        }
        if (eventoKurs.DateFrom && new Date(eventoKurs.DateFrom) > now) {
            return false; // nocht nicht angefangen
        }
        return true;
    }

    return(
        <div className="auswahlliste">
            <KategorieAuswahl
                setNotificationMessage={setNotificationMessage}
            />
            <GruppeAuswahl
                setNotificationMessage={setNotificationMessage}
            />
            <ThemaAuswahl
                setNotificationMessage={setNotificationMessage}
            />
            <KursAuswahl
                setNotificationMessage={setNotificationMessage}
            />
            <div className="eventoKurseTitle auswahllisteLine">
                <h2>Evento-Kurse:</h2>
                <p className="evento-tags evento-tags-display">
                    {eventoKursFilter.AreaOfEducationId && <span className="tag-AreaOfEducation">
                        <a href="#" onClick={() => triggerEventoKursFilter("AreaOfEducationId", null, "")}>
                            {eventoKursFilterNames.AreaOfEducationId}
                        </a>
                    </span>}
                    {eventoKursFilter.EventCategoryId && <span className="tag-EventCategory">
                        <a href="#" onClick={() => triggerEventoKursFilter("EventCategoryId", null, "")}>
                            {eventoKursFilterNames.EventCategoryId}
                        </a>
                    </span>}
                    {eventoKursFilter.EventLevelId && <span className="tag-EventLevel">
                        <a href="#" onClick={() => triggerEventoKursFilter("EventLevelId", null, "")}>
                            {eventoKursFilterNames.EventLevelId}
                        </a>
                    </span>}
                    {eventoKursFilter.EventTypeId && <span className="tag-EventType">
                        <a href="#" onClick={() => triggerEventoKursFilter("EventTypeId", null, "")}>
                            {eventoKursFilterNames.EventTypeId}
                        </a>
                    </span>}
                </p>
            </div>
            <div className="eventoKurse">
                {
                    eventoKurse.map((eventoKurs, index) => {
                        if (eventoKursFilter &&
                            (
                                (eventoKursFilter.AreaOfEducationId && eventoKursFilter.AreaOfEducationId !== eventoKurs.AreaOfEducationId) ||
                                (eventoKursFilter.EventCategoryId && eventoKursFilter.EventCategoryId !== eventoKurs.EventCategoryId) ||
                                (eventoKursFilter.EventLevelId && eventoKursFilter.EventLevelId !== eventoKurs.EventLevelId) ||
                                (eventoKursFilter.EventTypeId && eventoKursFilter.EventTypeId !== eventoKurs.EventTypeId)
                            )) {
                            return <></>;
                        }
                        if (kursIstVorbei(eventoKurs)) {
                            return <></>
                        }

                        return <EventoKursAuswahl
                            eventoKurs={eventoKurs}
                            key={index}
                            triggerEventoKursFilter={triggerEventoKursFilter}
                            setNotificationMessage={setNotificationMessage}
                        />;
                    })
                }
            </div>
        </div>
    );
}

export default Auswahl;
