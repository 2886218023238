import {useDrag} from "react-dnd";

function GruppeAuswahl({setNotificationMessage}) {
    const [{isDragging}, dragGruppe] = useDrag(
        () => ({
            type: 'neue_gruppe',
            item: {
                type: 'neue_gruppe',
            },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            })
        }),
        []
    );

    const onClick = () => {
        if (!isDragging) {
            setNotificationMessage("'Neue Gruppe' mit drag&drop in die Tabelle ziehen um eine neue Gruppe zu erstellen.");
        }
    };

    return(
        <>
            <div className="neuesElement neueGruppe auswahllisteLine" ref={dragGruppe} onClick={onClick}><p>Neue Gruppe</p></div>
        </>
    );
}

export default GruppeAuswahl;
