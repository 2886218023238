import SkeletonForm from "./SkeletonForm";

function DeleteForm({item, callbackOk, callbackClose}) {
    return (
        <SkeletonForm callbackOk={callbackOk} callbackClose={callbackClose}>
            <h2>Element {item.name} löschen?</h2>
            {
                item.items && item.items.length ?
                    <>
                        <p className="full-width">Alle untergeordneten Elemente werden ebenfalls gelöscht:</p>
                        <ul className="full-width">
                            {
                                item.items.map(item => (
                                    <li key={item.id}>{item.name}</li>
                                ))
                            }
                        </ul>
                    </> :
                    <></>
            }
        </SkeletonForm>
    );
}

export default DeleteForm;
